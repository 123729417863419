/* You can add global styles to this file, and also import other style files */

$enable-smooth-scroll: false;
$link-color: #000;
$link-decoration: none;

@import "./../node_modules/bootstrap/scss/mixins/banner";
@include bsBanner("");

// scss-docs-start import-stack
$btn-padding-y-lg: 1.5rem;
$btn-padding-x-lg: 3rem;
$btn-padding-y: 1rem;
$btn-padding-x: 2.5rem;
$btn-font-size-lg: 1rem;
$btn-line-height: 1;
$input-padding-x: 0rem;
$border-radius: 2rem;
$border-radius-sm: 2rem;
$border-radius-lg: 2rem;
$border-radius-xl: 1rem;
$border-radius-xxl: 2rem;

.btn,
.display-1 {
  text-transform: uppercase;
}
@media screen and (max-width: 991px) {
  .display-1 {
    font-size: 40px !important;
  }
}
.btn {
  white-space: nowrap;
  line-height: 12px !important;
}
.btn-lg {
  white-space: nowrap;
  line-height: 12px !important;
  padding-bottom: 26px !important;
}

@font-face {
  font-family: "FatFrank";
  src: url("/assets/FatFrank-Heavy.woff2") format("woff2"),
    url("/assets/FatFrank-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-Italic.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-LightIt.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-LightIt.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-Medium.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-Light.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-MediumIt.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-MediumIt.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-ThinItalic.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-UltraLight.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-Thin.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-UltLightIt.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-UltLightIt.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-BoldItalic.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-XBlackIt.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-XBlackIt.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-Black.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-BlackIt.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-BlackIt.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-Regular.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-XBlack.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-XBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-Heavy.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-HeavyIt.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-HeavyIt.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("/assets/NeueHaasUnicaW1G-Bold.woff2") format("woff2"),
    url("/assets/NeueHaasUnicaW1G-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

$headings-color: #102772;
$headings-line-height: 1;
$input-font-size: 14px;
$headings-font-family: "Neue Haas Unica W1G";
$display-font-family: "FatFrank";
$font-family-sans-serif: "Neue Haas Unica W1G";
@import "./../node_modules/bootstrap/scss/functions";
@import "./../node_modules/bootstrap/scss/variables";
$form-floating-line-height: 1rem;
$form-floating-height: add(3.3rem, $input-height-border);
$body-bg: #f8f3ed;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 1.875;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
$container-padding-x: $grid-gutter-width * 2;
$enable-cssgrid: true;
// Colours
$primary: #102772;
$secondary: #a9adfc;
$yellow: #232222;

$display-font-sizes: (
  1: 3rem,
  2: 2.5rem,
  3: 2rem,
  4: 1.5rem,
  5: 1rem,
  6: 2.5rem,
);

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "light": $light,
  "dark": $dark,
);

$btn-font-weight: $font-weight-bold;
$input-border-radius: 0;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

@import "./../node_modules/bootstrap/scss/variables-dark";
@import "./../node_modules/bootstrap/scss/maps";
@import "./../node_modules/bootstrap/scss/mixins";
@import "./../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "./../node_modules/bootstrap/scss/root";
@import "./../node_modules/bootstrap/scss/reboot";
@import "./../node_modules/bootstrap/scss/type";
@import "./../node_modules/bootstrap/scss/images";
@import "./../node_modules/bootstrap/scss/containers";
@import "./../node_modules/bootstrap/scss/grid";
@import "./../node_modules/bootstrap/scss/forms";
@import "./../node_modules/bootstrap/scss/buttons";
@import "./../node_modules/bootstrap/scss/button-group";
@import "./../node_modules/bootstrap/scss/nav";
@import "./../node_modules/bootstrap/scss/navbar";
@import "./../node_modules/bootstrap/scss/close";
@import "./../node_modules/bootstrap/scss/toasts";
@import "./../node_modules/bootstrap/scss/progress";

// Helpers
@import "./../node_modules/bootstrap/scss/helpers";

// Utilities
@import "./../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

body {
  letter-spacing: -0.16px;
}
.bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
img {
  object-fit: cover;
}
.action {
  cursor: pointer;
}
blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}
.text-white * {
  color: #fff !important;
}
storyblok-rich-text a {
  text-decoration: underline;
}
